import { template as template_bcaa5b238a444a4298a80e716d554f62 } from "@ember/template-compiler";
const FKLabel = template_bcaa5b238a444a4298a80e716d554f62(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
