import { template as template_2dba84250b5b49e089533a67d2b23ef6 } from "@ember/template-compiler";
const WelcomeHeader = template_2dba84250b5b49e089533a67d2b23ef6(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
