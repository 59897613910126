import { template as template_df72206b15ea4dc5b4c4dd21c216b9cc } from "@ember/template-compiler";
const FKControlMenuContainer = template_df72206b15ea4dc5b4c4dd21c216b9cc(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
